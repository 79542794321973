<template>
    <div class="billing-details">
        <fieldset class="grid grid-cols-1 md:grid-cols-2">
            <input v-model="form.first_name" type="text" name="billing_first_name" placeholder="First Name" @blur="validateForm">
            <input v-model="form.last_name" type="text" name="billing_last_name" placeholder="Last Name" @blur="validateForm">
        </fieldset>

        <fieldset class="grid grid-cols-1">
            <input v-model="form.address_1" type="text" name="billing_address_1" placeholder="Street Address 1" @blur="validateForm">
        </fieldset>

        <fieldset class="grid grid-cols-1">
            <input v-model="form.address_2" type="text" name="billing_address_2" placeholder="Street Address 2" @blur="validateForm">
        </fieldset>

        <fieldset class="grid grid-cols-1">
            <input v-model="form.city" type="text" name="billing_city" placeholder="City" @blur="validateForm">
        </fieldset>

        <fieldset class="grid grid-cols-1 md:grid-cols-2">
            <input v-model="form.state" type="text" name="billing_state" placeholder="State" @blur="validateForm">
            <input v-model="form.zip" type="text" name="billing_zip" placeholder="Zip" @blur="validateForm">
        </fieldset>

        <div class="card-details">
            <fieldset class="grid grid-cols-1">
                <input v-model="form.card_number" type="text" id="billing_card_number" name="billing_card_number" placeholder="Card Number (without spaces)" @input="validateForm">
            </fieldset>

            <fieldset class="grid grid-cols-1 md:grid-cols-3">
                <input v-model="form.card_month" type="text" name="billing_card_month" placeholder="Card Month (e.g. 04)" @input="validateForm">
                <input v-model="form.card_year" type="text" name="billing_card_year" placeholder="Card Year (e.g. 2024)" @input="validateForm">
                <input v-model="form.card_cc" type="text" name="billing_card_cc" placeholder="CC (e.g. 123)" @input="validateForm">
            </fieldset>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Object
        }
    },

    emits: ['update:modelValue', 'complete'],

    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                zip: '',
                card_number: '',
                card_month: '',
                card_year: '',
                card_cc: '',
            }
        };
    },

    watch: {
        form: {
            handler(formData) {
                this.$emit('update:modelValue', formData);
            },
            deep: true
        }
    },

    methods: {
        validInput(field, inputVal) {
            switch (field) {
                // case 'card_number': {
                //     return inputVal.length === 16;
                // }
                case 'card_month': {
                    return Number(inputVal) >= 1 && Number(inputVal) <= 12;
                }
                case 'card_year': {
                    return (Number(inputVal) >= 2023 && Number(inputVal) < 2040);
                }
                default: {
                    return inputVal !== '';
                }
            }
        },

        validateForm() {
            let isValid = true;
            for (let [field, value] of Object.entries(this.form)) {
                if (field === 'address_2') {
                    continue;
                }
                if (!this.validInput(field, value)) {
                    isValid = false;
                }
            }
            if (isValid) {
                this.$emit('complete', this.form);
            } else {
                this.$emit('incomplete', this.form);
            }
        }
    }
}
</script>