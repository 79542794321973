<template>
    <div id="registration-form" class="registration-form">
        <form v-if="registrationIsOpen && !registrationSuccess">
            <!-- STEP ONE (Non-Vendor) -->
            <div class="registration-form__step" v-if="!isVendorForm">
                <form-section :display="isStep(1) && !hasActiveUnit">
                    <fieldset>
                        <label for="unit_code">
                            Union No.
                        </label>
                        <input v-model="unitCode"
                               type="text"
                               id="unit_code"
                               name="unit_code"
                               placeholder="Union No.">
                    </fieldset>
                    <button class="registration-form__btn"
                            :class="{'registration-form__btn--loading': validating}"
                            :disabled="!hasUnitCode || validating"
                            @click.prevent="validateInviteCode">
                        <span>Validate</span>
                    </button>
                    <transition name="slide-fade">
                        <div class="form-alert" v-if="unitCodeNotFound">
                            <p class="is-error">{{ options.unit_code_not_found_message }}</p>
                        </div>
                    </transition>
                </form-section>
                <form-section :display="isStep(1) && hasActiveUnit && !unitConfirmed">
                    <p>Are you registering for <strong>{{ unitAssociation }}</strong>?</p>
                    <div class="registration-form__btn-group">
                        <button @click.prevent="confirmUnit">Continue</button>
                        <button @click.prevent="clearUnit">Try Different Code</button>
                    </div>
                </form-section>
                <form-section :display="unitConfirmed">
                    <contact-information v-model="poc">
                        <template #top>
                            <p class="department-unit"><strong>Department:</strong> {{ unitAssociation }}</p>
                        </template>
                        <template #bottom="{ data }">
                            <fieldset>
                                <label for="business_agent_name">Business Agent's Name</label>
                                <select name="business_agent_name" id="business_agent_name" v-model="businessAgentEmail">
                                    <option value="">-- Please select an option --</option>
                                    <option v-for="(baEmail, baName) in availableBusinessAgents" :value="baEmail">
                                        {{ baName }}
                                    </option>
                                </select>
                            </fieldset>
                            <fieldset>
                                <p>Are you attending?</p>
                                <input v-model="data.attending" id="poc_attending" name="areAttending" type="radio" :value="true">
                                <label for="poc_attending">Yes</label>
                                <input v-model="data.attending" id="poc_not_attending" name="areAttending" type="radio" :value="false">
                                <label for="poc_not_attending">No</label>
                            </fieldset>
                            <button v-if="isStep(1)" :disabled="!hasValidPoc" @click.prevent="addPoc">Continue</button>
                        </template>
                    </contact-information>
                </form-section>
            </div>

            <!-- STEP ONE (Vendor) -->
            <div class="registration-form__step" v-if="isVendorForm">
                <form-section :display="isStep(1)">
                    <h3>How Would You Like To Support This Event?</h3>

                    <fieldset class="radio-group">
                        <label for="vendor_type_exhibitor">
                            <input v-model="vendorType" id="vendor_type_exhibitor" name="vendor_type" type="radio" value="exhibitor">
                            Exhibitor
                        </label>

                        <label for="vendor_type_sponsor">
                            <input v-model="vendorType" id="vendor_type_sponsor" name="vendor_type" type="radio" value="sponsor">
                            Sponsor
                        </label>
                    </fieldset>

                    <p class="vendor-links">Learn More About:
                        <a :href="event.exhibitor_details_page_url" target="_blank">Exhibitor Perks</a>
                        <a :href="event.sponsor_details_page_url" target="_blank">Sponsor Perks</a>
                    </p>

                    <fieldset v-if="vendorIsSponsor">
                        <p>Please enter your sponsor promo text:</p>
                        <textarea v-model="sponsorPromoText" name="sponsor_promo_text" cols="30" rows="5"></textarea>
                    </fieldset>

                </form-section>
                <form-section :display="hasVendorType">
                    <contact-information v-model="poc">
                        <template #top="{ data }">
                            <fieldset class="grid grid-cols-1">
                                <input v-model="data.company" type="text" name="poc_company" placeholder="Company Name">
                            </fieldset>
                        </template>

                        <template #bottom>
                            <button v-if="isStep(1)" :disabled="!hasValidPoc" @click.prevent="addVendorPoc">Continue</button>
                        </template>
                    </contact-information>
                </form-section>
            </div>

            <!-- STEP TWO -->
            <transition name="slide-fade">
                <div v-if="step > 1 && allowsAttendees" class="registration-form__step registration-form__step--attendees">
                    <attendee-list v-model="attendees">
                        <template #actions="{hasMaxAttendees, addEmptyAttendee}">
                            <div class="registration-form__btn-group">
                                <button v-if="step <= 2" @click.prevent="goToBilling">Continue</button>
                                <button :disabled="hasMaxAttendees" @click.prevent="addEmptyAttendee">Add Another Attendee</button>
                            </div>
                        </template>
                    </attendee-list>
                </div>
            </transition>

            <transition name="slide-fade">
                <div v-if="isStep(3)" class="registration-form__step registration-form__step--registration-note">
                    <div class="registration-note">
                        <label class="registration-note__label">{{ registrationNoteLabel }}</label>
                        <p class="registration-note__description">{{ registrationNoteDescription }}</p>
                        <textarea name="registration-note"
                                  v-model="registrationNote"></textarea>
                    </div>
                </div>
            </transition>

            <div v-if="isStep(3)" class="registration-form__step registration-form__step--billing">
                <form-section :display="isStep(3)">
                    <h3>Billing Information</h3>

                    <div class="promo-code" v-if="eventHasCouponCode && (needsBilling || usedValidPromo)">
                        <p>Add a Promo Code </p>
                        <input v-model="promoCode" type="text" name="promo_code">
                        <button v-if="!usedValidPromo" @click.prevent="verifyPromoCode">Add</button>
                        <button v-else @click.prevent="removePromoCode" class="accent">Remove</button>
                    </div>
                    <transition name="slide-fade">
                        <div class="form-alert" v-if="usedValidPromo">
                            <p class="is-success">Successfully added promo code!</p>
                        </div>
                        <div class="form-alert" v-else-if="usedInvalidPromo">
                            <p class="is-error">The promo code is invalid.</p>
                        </div>
                    </transition>

                    <p class="total-price"><strong>Total price: ${{ totalPrice }}</strong></p>

                    <transition name="slide-fade">
                        <billing-details v-if="needsBilling"
                                         v-model="paymentForm"
                                         @complete="onBillingComplete"
                                         @incomplete="hasValidBilling = false"></billing-details>
                    </transition>
                </form-section>
            </div>

            <div v-if="isStep(3)">
                <div style="margin-bottom: 15px;" v-if="needsBilling">
                    <label for="card-auth">
                        <input type="checkbox" id="card-auth" v-model="hasCardAuthority"><span>I am the card owner or have authority to utilize this payment method.</span>
                    </label>
                </div>
                <div class="form-alert" v-if="registrationFailure">
                    <div class="is-error" v-html="registrationFailureMessage"></div>
                </div>
                <button type="submit"
                        class="registration-form__btn"
                        :class="{'registration-form__btn--loading': submitting}"
                        :disabled="!isFormComplete || submitting"
                        @click.prevent="submit">
                    <span>Register</span>
                </button>
            </div>
        </form>
        <div class="form-alert" v-else-if="!registrationIsOpen" v-html="registrationClosedMessage"></div>
        <div class="form-alert" v-else-if="registrationSuccess">
            <p class="is-success" style="margin-bottom: 15px;"><strong>Thanks, you have successfully registered.</strong></p>
            <p class="is-success">Please be on the lookout for your receipt in your email and forthcoming communication from the POAM office.</p>
        </div>
    </div>
</template>

<script>
import {computed, onMounted, provide, ref} from "vue";
import axios from 'axios';
import Swal from 'sweetalert2';
import {generateUuid} from "../../util/generate-uuid";
import SlideVertical from "./../transitions/SlideVertical";
import useAjaxUrl from "../../composables/use-ajax-url";
import useActivities from "../../composables/use-activities";
import FormSection from "./FormSection";
import ContactInformation from "./ContactInformation";
import AttendeeList from "./AttendeeList";
import BillingDetails from "./BillingDetails";

const DEFAULT_ATTENDEE_DATA = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: 'union-member',
    activities: {},
    complimentary_activities: [],
    special_free_activities: [],
    receiving_award: false,
    awards: [],
    swag: {},
    guest: null,
    price: 0,
};

const POAM_STAFF = {
    unit_code: 'POAM',
    attendee_key: 'poam-staff',
};

const GUEST_TYPES = {
    poam: 'poam-special-guest',
    vip: 'vip-guest',
    union_member: 'union-member-guest',
};

export default {
    components: {
        'transition-slide': SlideVertical,
        FormSection,
        ContactInformation,
        AttendeeList,
        BillingDetails,
    },

    props: {
        event: {
            type: Object
        },
        vendor: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object
        },
    },

    data() {
        return {
            step: 1,
            unitConfirmed: false,
            poc: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                attending: true,
                company: null,
            },
            paymentForm: {
                first_name: '',
                last_name: '',
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                zip: '',
                card_number: '',
                card_month: '',
                card_year: '',
                card_cc: '',
            },
            hasCardAuthority: false,
            validating: false,
            unitCodeNotFound: false,
            submitting: false,
            registrationSuccess: false,
            sponsorPromoText: '',
            registrationFailure: false,
            registrationFailureMessage: ''
        };
    },

    setup(props, { attrs, slots, emit }) {

        const unitCode = ref('');

        const unit = ref(null);

        const vendorType = ref(null);

        const registrationIsOpen = computed(() => {
            if (isVendorForm.value) {
                return !props.event.vendor_registration_closed || true;
            }
            return !props.event.registration_closed || true;
        });

        const registrationClosedMessage = computed(() => {
            if (isVendorForm.value) {
                return props.event.vendor_registration_closed_message;
            }
            return props.event.registration_closed_message;
        });

        const eventHasCouponCode = computed(() => {
            return !!props.event.zero_cost_coupon_code || false;
        });

        const zeroCostCouponCode = computed(() => {
            if (eventHasCouponCode.value) {
                return props.event.zero_cost_coupon_code;
            }
            return null;
        });

        const zeroCostUnitCode = computed(() => {
            return props.event.zero_cost_unit_code || null;
        });

        const isVendorForm = computed(() => {
            return !!props.vendor;
        });

        const vendorIsSponsor = computed(() => {
            return isVendorForm.value && vendorType.value === 'sponsor';
        });

        const vendorFeeByType = computed(() => {
            return {
                exhibitor: Number(props.event.exhibitor_cost),
                sponsor: Number(props.event.sponsor_cost)
            };
        });

        const getVendorFee = (type = null) => {
            let typeOfVendor = type ? type : vendorType.value;
            return vendorFeeByType.value[typeOfVendor];
        };

        const hasZeroCostUnitCode = computed(() => {
            return !!unit.value && unit.value.hasOwnProperty('code') && unit.value.code.toUpperCase() === zeroCostUnitCode.value.toUpperCase();
        });

        const isPoamUnit = computed(() => {
            return !!unit.value && unit.value.hasOwnProperty('code') && unit.value.code.toUpperCase() === POAM_STAFF.unit_code;
        });

        const registrationNoteLabel = computed(() => {
            return !!props.event.registration_note_label ? props.event.registration_note_label : 'Registration Note';
        });

        const registrationNoteDescription = computed(() => {
            return !!props.event.registration_note_description ? props.event.registration_note_description : 'Add any special notes about your registration here';
        });

        const registrationNote = ref('');

        const vipLabelSingular = computed(() => {
            return !!props.event.vip_label_singular ? props.event.vip_label_singular : 'VIP';
        });

        const vipLabelPlural = computed(() => {
            return !!props.event.vip_label_plural ? props.event.vip_label_plural : 'VIPs';
        });

        const getVipLabel = (plural = false) => {
            return !plural ? vipLabelSingular.value : vipLabelPlural.value;
        };

        const attendees = ref([]);

        const activities = computed(() => {
            if (isVendorForm.value) {
                return props.event.vendor_activities || [];
            }
            if (props.event.hasOwnProperty('activities') && Array.isArray(props.event.activities)) {
                return props.event.activities;
            }
            return [];
        });

        const availableBusinessAgents = computed(() => {
            return props.options.business_agents;
        });

        const businessAgentEmail = ref('');

        const businessAgentName = computed(() => {
            if (businessAgentEmail.value !== '') {
                for (let [baName, baEmail] of Object.entries(availableBusinessAgents.value)) {
                    if (businessAgentEmail.value === baEmail) {
                        return baName;
                    }
                }
            }
            return '';
        });

        const vipNotFoundMessage = computed(() => {
            return props.options.vip_not_found_message;
        });

        const offersComps = computed(() => {
            if (props.event.hasOwnProperty('comps_offered')) {
                return props.event.comps_offered === '1';
            }
            return false;
        });

        const allComps = computed(() => {
            if (!offersComps.value || !props.event.hasOwnProperty('comps')) {
                return [];
            }

            return props.event.comps;
        });

        const compsByUnit = ref({});

        const initialUnitComps = computed(() => {
            if (!offersComps.value || !unit.value) {
                return null;
            }

            if (unit.value.hasOwnProperty('id') && compsByUnit.value.hasOwnProperty(unit.value.id)) {
                return compsByUnit.value[unit.value.id];
            }

            return null;
        });

        const currentUnitComps = ref(null);

        const refreshUnitCompUsage = () => {
            currentUnitComps.value = initialUnitComps.value ? JSON.parse(JSON.stringify(initialUnitComps.value)) : null;
        };

        const useUnitComp = (name) => {
            if (currentUnitComps.value && currentUnitComps.value.hasOwnProperty(name) && currentUnitComps.value[name] > 0) {
                currentUnitComps.value[name] -= 1;
            }
        };

        const removeUsedUnitComp = (name) => {
            if (currentUnitComps.value && currentUnitComps.value.hasOwnProperty(name)) {
                currentUnitComps.value[name] += 1;
            }
        };

        const setComps = () => {
            let pattern = /unit_id_(\d+)_count/;
            for (let comp of allComps.value) {
                for (let [key, val] of Object.entries(comp)) {
                    if (key === 'complimentary_activity') {
                        continue;
                    }
                    let matchedUnit = key.match(pattern);
                    if (matchedUnit.length < 2) {
                        continue;
                    }
                    let unit = matchedUnit[1];
                    if (!compsByUnit.value.hasOwnProperty(unit)) {
                        compsByUnit.value[unit] = {};
                    }
                    compsByUnit.value[unit][comp.complimentary_activity] = Number(val);
                }
            }
        };

        const attendeeTypeKeys = computed(() => {
            return [... new Set(activities.value.map((activity) => {
                return activity.attendees.map((attendee) => isVendorForm.value ? attendee.vendor_type : attendee.attendee_type);
            }).flat())];
        });

        const vendorTypes = computed(() => {
            if (Array.isArray(props.event.vendor_types)) {
                return props.event.vendor_types;
            } else if (typeof props.event.vendor_types === 'string') {
                return [props.event.vendor_types];
            }
            return [];
        });

        const attendeeTypes = computed(() => {
            let types = {};
            for (let typeKey of attendeeTypeKeys.value) {
                // don't add POAM staff or guest attendee types for non-POAM units
                if ((typeKey === POAM_STAFF.attendee_key || typeKey === GUEST_TYPES.poam ) && !isPoamUnit.value) {
                    continue;
                }
                if (isVendorForm.value) {
                    types[typeKey] = typeKey === 'sponsor' ? 'Sponsor': 'Vendor';
                    continue;
                }
                types[typeKey] = props.options.attendee_types[typeKey];
            }
            return types;
        });

        const addAttendee = (attendeeData, atIndex = null) => {
            if (!attendeeData.hasOwnProperty('id')) {
                attendeeData.id = generateUuid();
            }

            if (atIndex !== null) {
                attendees.value[atIndex] = attendeeData;
            } else {
                attendees.value.push(attendeeData);
            }
        };

        const removeAttendeeById = (id) => {
            let index = attendees.value.findIndex((attendee) => attendee.id === id);

            if (index >= 0) {
                attendees.value.splice(index, 1);
            }
        };

        const getDefaultAttendeeData = () => {
            if (isVendorForm.value) {
                return {
                    ...DEFAULT_ATTENDEE_DATA,
                    type: vendorType.value || 'vendor'
                }
            }
            return DEFAULT_ATTENDEE_DATA;
        };

        const addEmptyAttendee = () => {
            addAttendee({
                ...getDefaultAttendeeData(),
            });
        };

        const addAttendeeGuest = (attendeeId) => {
            let attendeeIndex = attendees.value.findIndex((attendee) => attendee.id === attendeeId);

            if (attendeeIndex >= 0) {
                attendees.value[attendeeIndex].guest = {
                    ...getDefaultAttendeeData(),
                    id: generateUuid(),
                    type: attendees.value[attendeeIndex].type === 'vip' ? GUEST_TYPES.vip : GUEST_TYPES.union_member,
                };
            }
        };

        const { ajaxUrl } = useAjaxUrl();

        const removeAttendeeGuest = (attendeeId) => {
            let attendeeIndex = attendees.value.findIndex((attendee) => attendee.id === attendeeId);

            if (attendeeIndex >= 0) {
                attendees.value[attendeeIndex].guest = null;
            }
        };

        const {
            eventActivitiesByType,
            getActivityTypeLabel
        } = useActivities(activities.value, props.options, isVendorForm.value);

        provide('event', props.event);

        provide('eventOptions', props.options);

        provide('unit', unit);

        provide('isVendorForm', isVendorForm);

        provide('vipNotFoundMessage', vipNotFoundMessage);

        provide('getVipLabel', getVipLabel);

        provide('comps', {
            initialUnitComps,
            currentUnitComps,
            useUnitComp,
            removeUsedUnitComp,
        });

        provide('attendeesState', {
            attendees,
            activities,
            attendeeTypes,
            addAttendee,
            addEmptyAttendee,
            removeAttendeeById,
            addAttendeeGuest,
            removeAttendeeGuest,
        });

        const totalPrice = computed(() => {
            let price = 0;
            if (usedValidPromo.value || hasZeroCostUnitCode.value) {
                return price;
            }
            for (let attendee of attendees.value) {
                price += attendee.price;
                if (attendee.guest !== null && attendee.guest.hasOwnProperty('price') && attendee.guest.price > 0) {
                    price += attendee.guest.price;
                }
            }
            if (isVendorForm.value) {
                price += getVendorFee();
            }
            return price;
        });

        const promoCode = ref('');

        const usedValidPromo = ref(false);

        const usedInvalidPromo = ref(false);

        const verifyPromoCode = () => {
            usedValidPromo.value = promoCode.value === zeroCostCouponCode.value;
            usedInvalidPromo.value = !usedValidPromo.value;
        }

        const removePromoCode = () => {
            usedValidPromo.value = false;
            usedInvalidPromo.value = false;
            promoCode.value = '';
        }

        const needsBilling = computed(() => {
            return !usedValidPromo.value && !hasZeroCostUnitCode.value && totalPrice.value > 0;
        });

        const hasValidBilling = ref(false);

        const onBillingComplete = (formData) => {
            hasValidBilling.value = true;
        };

        onMounted(() => {
            setComps();
        });

        return {
            ajaxUrl,
            unitCode,
            unit,
            registrationIsOpen,
            registrationClosedMessage,
            isVendorForm,
            vendorType,
            isPoamUnit,
            vipLabelSingular,
            vipLabelPlural,
            attendees,
            activities,
            attendeeTypes,
            getDefaultAttendeeData,
            addAttendee,
            addEmptyAttendee,
            removeAttendeeById,
            offersComps,
            allComps,
            compsByUnit,
            initialUnitComps,
            currentUnitComps,
            refreshUnitCompUsage,
            totalPrice,
            eventHasCouponCode,
            zeroCostCouponCode,
            zeroCostUnitCode,
            promoCode,
            usedValidPromo,
            usedInvalidPromo,
            verifyPromoCode,
            removePromoCode,
            needsBilling,
            hasValidBilling,
            onBillingComplete,
            vendorIsSponsor,
            vendorFeeByType,
            getVendorFee,
            registrationNoteLabel,
            registrationNoteDescription,
            registrationNote,
            businessAgentEmail,
            businessAgentName,
            availableBusinessAgents,
        };
    },

    computed: {
        hasUnitCode() {
            return this.unitCode !== '';
        },

        hasActiveUnit() {
            return this.unit !== null;
        },

        unitAssociation() {
            if (this.hasActiveUnit) {
                return this.unit.association;
            }
            return '';
        },

        hasValidPoc() {
            return this.poc.first_name !== ''
                && this.poc.last_name !== ''
                && this.poc.email !== ''
                && this.poc.phone !== '';
        },

        hasValidBusinessAgentName() {
            return this.businessAgentName !== '';
        },

        allowsAttendees() {
            return !this.isVendorForm || this.vendorsInvitedToActivities && !this.vendorIsSponsor;
        },

        hasVendorType() {
            return this.vendorType !== null;
        },

        vendorsInvitedToActivities() {
            return this.event.vendor_activities_offered === '1';
        },

        isFormComplete() {
            return this.step >= 3 && (!this.needsBilling || (this.hasValidBilling && this.hasCardAuthority));
        }
    },

    methods: {
        isStep(number) {
            return this.step === number;
        },

        confirmUnit() {
            this.unitConfirmed = true;
        },

        clearUnit() {
            this.unitCode = '';
            this.unit = null;
        },

        setUnit(unit) {
            this.unit = unit;
            // set unit comp usages
            this.$nextTick(() => {
                this.refreshUnitCompUsage();
            });
        },

        addPoc() {
            // If POC attending, add as 1st attendee
            if (this.poc.attending) {
                this.addAttendee({
                    ...this.getDefaultAttendeeData(),
                    ...this.poc
                }, 0);
            } else {
                this.addAttendee({
                    ...this.getDefaultAttendeeData(),
                }, 0);
            }

            this.step = 2;
        },

        addVendorPoc() {
            this.poc.attending = false;
            return this.vendorIsSponsor ? this.goToBilling() : this.addPoc();
        },

        goToBilling() {
            if (!this.validateAttendeeActivities()) {
                return;
            }
            this.step = 3;
        },

        validateBaseFormInputs() {
            if (this.hasValidPoc && this.hasValidBusinessAgentName || this.hasValidPoc && this.isVendorForm) {
                return true;
            }
            if (!this.isVendorForm && this.businessAgentName === '') {
                Swal.fire({
                    title: `Your Business Agent's name is required`,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return false;
            }
            let fields = ['first_name', 'last_name', 'email', 'phone'];
            let missing = [];
            for (let field of fields) {
                if (this.poc[field] === '') {
                    missing.push(field);
                }
            }
            let title = missing.length > 1 ? `The '${missing.join(', ')}' fields are required.` : `The ${missing[0]} field is required.`
            Swal.fire({
                title: title,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return false;
        },

        validateAttendeeActivities() {
            let attendeesWithNoActivities = [];
            for (let attendee of this.attendees) {
                if (Object.keys(attendee.activities).length > 0) {
                    continue;
                }
                attendeesWithNoActivities.push(`${attendee.first_name} ${attendee.last_name}`);
            }

            let alertText = '';

            let alertOptions = {
                title: 'No activities selected',
                icon: 'error',
                confirmButtonText: 'Ok'
            };

            if (attendeesWithNoActivities.length <= 0) {
                return true;
            } else if (attendeesWithNoActivities.length === 1) {
                alertText = `The attendee ${attendeesWithNoActivities[0]} has no activities selected. One or more activities must be selected to continue.`
                alertOptions.text = alertText;
            } else {
                alertText = `<p>The following attendees have no activities selected:</p><ul>`;
                for (let attendeeName of attendeesWithNoActivities) {
                    alertText += `<li>${attendeeName}</li>`
                }
                alertText += `</ul><p>One or more activities must be selected to continue.</p>`;
                alertOptions.html = alertText;
            }
            Swal.fire(alertOptions);

            return false;
        },

        async validateInviteCode() {
            this.validating = true;
            this.unitCodeNotFound = false;
            await this.sendInviteRequest();
            this.validating = false;
        },

        sendInviteRequest() {
            return new Promise((resolve, reject) => {
                let formData = this.prepareRequestData('unit_code_request');
                formData.append('unit_code', this.unitCode);

                axios.post(this.ajaxUrl, formData)
                    .then(response => {
                        if (response.data.success) {
                            this.setUnit(response.data.unit);
                        } else {
                            this.unitCodeNotFound = true;
                        }
                        resolve();
                    })
                    .catch(err => {
                        console.log("Error:");
                        console.log(err);
                        this.unitCodeNotFound = true;

                        reject();
                    });
            });
        },

        async submit() {
            if (!this.validateBaseFormInputs() || !this.validateAttendeeActivities()) {
                return;
            }

            this.submitting = true;

            await this.sendRequest();

            this.submitting = false;

            if (this.registrationSuccess) {
                document.querySelector('#page-title').scrollIntoView({ behavior: 'smooth' });
            }
        },

        prepareRequestData(action) {
            let formData = new FormData;
            formData.append('action', action);
            return formData;
        },

        getFormData() {
            let formData = this.prepareRequestData('process_event_form');
            formData.append('event_id', this.event.id);
            formData.append('unit_id', this.unit ? this.unit.id : null);

            // Business Agent Name
            formData.append('business_agent_name', this.businessAgentName);
            formData.append('business_agent_email', this.businessAgentEmail);

            // POC
            for (let [field, val] of Object.entries(this.poc)) {
                formData.append(`poc_${field}`, val);
            }

            // billing info
            for (let [field, val] of Object.entries(this.paymentForm)) {
                formData.append(`billing_${field}`, val);
            }

            // attendees
            formData = this.createObjectFormData(formData, 'attendees', this.attendees);

            // price
            formData.append('price', this.totalPrice);
            formData.append('promo_used', this.usedValidPromo ? this.promoCode : null);

            // vendor-specific data
            formData.append('is_vendor', this.isVendorForm);
            formData.append('vendor_type', this.vendorType);
            formData.append('sponsor_promo', this.sponsorPromoText);

            // registration note
            formData.append('registration_note', this.registrationNote);

            return formData;
        },

        // allows vars with nested obj/arrays to be sent via FormData object
        createObjectFormData(formData, key, data) {
            if (data === Object(data) || Array.isArray(data)) {
                for (let i in data) {
                    formData = this.createObjectFormData(formData, key + '[' + i + ']', data[i]);
                }
            } else {
                formData.append(key, data);
            }
            return formData;
        },

        sendRequest() {
            return new Promise((resolve, reject) => {
                this.registrationFailure = false;
                this.registrationFailureMessage = '';

                axios.post(this.ajaxUrl, this.getFormData(), {})
                    .then(response => {
                        if (response.data.success) {
                            this.registrationSuccess = true;
                            resolve();
                        }
                        this.registrationFailureMessage = `<p class="is-error">Error: ${response.data.message}</p>`;
                        this.registrationFailure = true;
                        resolve();
                    })
                    .catch(err => {
                        console.log("Error:");
                        console.log(err);
                        if (axios.isAxiosError(err)) {
                            // Something weird has been occurring with AxiosError being thrown, so pretend everything's all good
                            this.registrationSuccess = true;
                            resolve();
                        }
                        this.registrationFailureMessage = "<p class='is-error' style='margin-bottom: 10px'>Whoops, an error occurred during registration.</p><br><p class='is-error'><strong>Important: Do not attempt to register again at this moment. Please contact POAM to assist and confirm the issue you are having. If possible, please take a screenshot or record the error you are seeing.</strong></p>";
                        this.registrationFailure = true;
                        reject();
                    });
            });
        },
    }
}
</script>

<style lang="scss">
.registration-form {
    form fieldset {
        margin-bottom: 20px;

        &.radio-group,
        &.checkbox-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 0.35rem;

            label {
                font-weight: 400;
            }
        }
    }

    &__step {
        &--attendees {
            margin-top: -2rem;
        }

        &--registration-note {
            margin-top: 3rem;
        }

        &--billing {
            margin-top: 3rem;
        }
    }

    &__section {
        margin-bottom: 20px;
    }

    &__btn-group {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__btn {
        position: relative;
        transition: all 300ms ease-in-out;

        &--loading {

            > span {
                visibility: hidden;
                opacity: 0;
            }

            &::after {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border: 4px solid transparent;
                border-top-color: #ffffff;
                border-radius: 50%;
                animation: button-loading-spinner 1s ease infinite;
            }
        }
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }

    .form-alert,
    .attendee-type-validation {
        width: 100%;
        padding: 1rem;
        background-color: #ffffff;
        border-radius: 3px;
        margin: 1rem 0;

        p {
            margin: 0;

            &.is-success {
                color: #1c725e;
            }

            &.is-error {
                color: #c73d3d;
            }
        }
    }

    .attendee {
        padding: 1rem;
        margin: 1rem 0;
        border: 1px solid rgba(#001095, 0.5);

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .activity-selector {
        &__main,
        &__additional-item-activity {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
        }

        &__additional-item-activity {
            gap: 1.5rem;
        }

        &__additional, &__additional-items {
            display: flex;
            flex-direction: column;
        }

        &__additional {
            margin: 30px 0;
        }

        &__activities,
        &__pricing {
            flex: 1 0 45%;
        }

        &__pricing {
            background-color: rgba(#6E89AB, 0.25);
            padding: 1.5rem;
        }

        .price {
            &__voucher {
                margin-left: 5px;
            }

            &--strike {
                text-decoration: line-through;
            }
        }
    }

    .tooltip {
        &__wrapper {
            margin-left: 5px;
        }

        &__icon {
            height: 24px;
            width: 24px;
            margin-bottom: -4px;
            transition: opacity 250ms ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }

    .promo-code {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 580px;
        margin: 30px 0;

        p {
            margin: 0 10px 0 0;
        }

        p, input {
            flex: 1 0 auto;
        }

        input {
            max-width: 400px;
        }
    }

    .department-unit {
        margin: 30px 0 40px;
    }

    .total-price {
        font-size: 1.35rem;
        margin-bottom: 30px;
    }

    .card-details {
        margin: 50px 0 30px;
    }

    .vendor-links {
        a {
            display: inline-block;
            text-decoration: underline;
            margin: 0 10px;
        }
    }
}

</style>