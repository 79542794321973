import { ref, computed, provide, onMounted } from 'vue';
import useSwag from "./use-swag";

const useActivities = (eventActivities, eventOptions, forVendor = false) => {
    const eventActivitiesByType = ref({});

    const activityTypes = eventOptions.activity_types;

    const conditionalGuestTypes = {
        vip: 'vip-guest',
        'union-member': 'union-member-guest',
    };

    const {
        activityHasSwag,
        getActivitySwag,
    } = useSwag(eventOptions);

    const getActivityTypeLabel = (activitySlug) => {
        return activityTypes[activitySlug] || activitySlug;
    };

    const attendeeTypeKey = computed(() => {
        return forVendor ? 'vendor_type' : 'attendee_type';
    });

    const activityHasAttendanceRestrictions = (activity) => {
        return activity.hasOwnProperty('attendance_restrictions') && activity.attendance_restrictions === '1';
    };

    const getActivityAttendanceRestrictions = (activity) => {
        if (!activity.hasOwnProperty('award_restrictions')) {
            return [];
        }
        return Array.isArray(activity.award_restrictions) ? activity.award_restrictions : [];
    };

    const gatherEventActivitiesByType = () => {
        for (let activity of eventActivities) {
            let allAttendeeTypes = activity.attendees.map((attendee) => attendee[attendeeTypeKey.value]);
            let typesAllowedGuest = [];
            for (let [attendeeType, guestType] of Object.entries(conditionalGuestTypes)) {
                if (allAttendeeTypes.includes(guestType)) {
                    typesAllowedGuest.push(attendeeType);
                }
            }
            for (let attendee of activity.attendees) {
                let attendeeType = attendee[attendeeTypeKey.value];
                if (!eventActivitiesByType.value.hasOwnProperty(attendeeType)) {
                    eventActivitiesByType.value[attendeeType] = {};
                }
                let activitySlug = activity.activity;
                if (!eventActivitiesByType.value[attendeeType].hasOwnProperty(activitySlug)) {
                    eventActivitiesByType.value[attendeeType][activitySlug] = {
                        label: getActivityTypeLabel(activitySlug)
                    };
                }
                eventActivitiesByType.value[attendeeType][activitySlug].price = Number(attendee.price);
                eventActivitiesByType.value[attendeeType][activitySlug].attending = attendee.attending === "1";
                eventActivitiesByType.value[attendeeType][activitySlug].has_swag = activityHasSwag(activity);
                eventActivitiesByType.value[attendeeType][activitySlug].swag = getActivitySwag(activity);
                eventActivitiesByType.value[attendeeType][activitySlug].has_attendance_restrictions = activityHasAttendanceRestrictions(attendee);
                eventActivitiesByType.value[attendeeType][activitySlug].award_restrictions = getActivityAttendanceRestrictions(attendee);
                eventActivitiesByType.value[attendeeType][activitySlug].allowed_guest = typesAllowedGuest.includes(attendeeType);
                eventActivitiesByType.value[attendeeType][activitySlug].tooltip = activity.hasOwnProperty('tooltip') && !!activity.tooltip ? activity.tooltip : null;
            }
        }
    };

    provide('eventActivitiesByType', eventActivitiesByType);

    provide('getActivityTypeLabel', getActivityTypeLabel);

    onMounted(() => {
        gatherEventActivitiesByType();
    });

    return {
        eventActivitiesByType,
        getActivityTypeLabel
    };
};

export default useActivities;