import { ref, computed, provide, inject } from 'vue';

const useSwag = (options) => {

    const swagSizes = computed(() => {
        return options.swag_sizes;
    });

    const swagTypes = computed(() => {
        return options.swag_types;
    });

    const getSwagSizeLabel = (swagSizeSlug) => {
        return swagSizes.value[swagSizeSlug] || swagSizeSlug;
    };

    const getSwagTypeLabel = (swagTypeSlug) => {
        return swagTypes.value[swagTypeSlug] || swagTypeSlug;
    };

    const activityHasSwag = (activity) => {
        return activity.hasOwnProperty('has_swag') && activity.has_swag === '1';
    };

    const getActivitySwag = (activity) => {
        if (!activity.hasOwnProperty('swag')) {
            return null;
        }
        let swag = {};
        for (let item of activity.swag) {
            if (!swag.hasOwnProperty(item.swag_type)) {
                swag[item.swag_type] = {
                    type: item.swag_type,
                    label: getSwagTypeLabel(item.swag_type)
                };
            }
            swag[item.swag_type].sizes = {};
            for (let size of item.swag_size) {
                swag[item.swag_type].sizes[size] = getSwagSizeLabel(size);
            }
        }
        return swag;
    };

    provide('getSwagSizeLabel', getSwagSizeLabel);
    provide('getSwagTypeLabel', getSwagTypeLabel);

    return {
        swagSizes,
        swagTypes,
        getSwagSizeLabel,
        getSwagTypeLabel,
        activityHasSwag,
        getActivitySwag,
    };
};

export default useSwag;