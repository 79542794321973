<template>
    <h3>Contact Information</h3>

    <slot name="top" v-bind="{data: poc}"></slot>

    <fieldset class="grid grid-cols-1 md:grid-cols-2">
        <input v-model="poc.first_name" type="text" name="poc_first_name" placeholder="First Name" required>
        <input v-model="poc.last_name" type="text" name="poc_last_name" placeholder="Last Name" required>
    </fieldset>

    <fieldset class="grid grid-cols-1 md:grid-cols-2">
        <input v-model="poc.email" type="email" name="poc_email" placeholder="Email" required>
        <input v-model="poc.phone" type="tel" name="poc_phone" placeholder="Phone" required>
    </fieldset>

    <slot name="bottom" v-bind="{data: poc}"></slot>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Object
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            poc: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                attending: true,
                company: null,
            }
        };
    },

    watch: {
        poc: {
            handler(data) {
                this.$emit('update:modelValue', data);
            },
            deep: true
        }
    }
}
</script>