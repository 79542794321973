<template>
    <transition-slide :active="display" tag="section" class="registration-form__section">
        <slot></slot>
    </transition-slide>
</template>

<script>
import SlideVertical from "./../transitions/SlideVertical";

export default {
    components: {
        'transition-slide': SlideVertical,
    },

    props: {
        display: {
            type: Boolean,
            default: true
        }
    },
}
</script>