import { ref, provide, onMounted } from 'vue';

const useAjaxUrl = () => {

    const ajaxUrl = ref(null);

    provide('ajaxUrl', ajaxUrl);

    onMounted(() => {
        ajaxUrl.value = window.pa_events_data?.ajax_url;
    });

    return {
        ajaxUrl,
    };
};

export default useAjaxUrl;