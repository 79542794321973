<template>
    <div class="attendee-list">
        <h3>Attendee Information</h3>
        <transition-group name="slide-fade" mode="out-in">
            <attendee v-for="(attendee, index) in attendees"
                      :attendee="attendee"
                      :key="index">
                <template #header>
                    <div class="attendee__header">
                        <h4>Attendee {{ index + 1 }}</h4>
                        <small><a href="javascript:void(0)"
                                  v-if="index > 0"
                                  @click.prevent="removeAttendeeById(attendee.id)">X Remove Attendee</a></small>
                    </div>
                </template>
            </attendee>
        </transition-group>

        <slot name="actions" v-bind="{hasMaxAttendees, addEmptyAttendee}">
            <button :disabled="hasMaxAttendees" @click.prevent="addEmptyAttendee">Add Attendee</button>
        </slot>
    </div>
</template>

<script>
import { ref, inject } from 'vue';
import Attendee from "./Attendee";

export default {
    components: {
        'attendee': Attendee,
    },

    props: {
        modelValue: {
            type: Array
        },
        max: {
            type: Number
        }
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
        const attendees = ref(props.modelValue ? props.modelValue : []);

        const {
            activities,
            attendeeTypes,
            addAttendee,
            addEmptyAttendee,
            removeAttendeeById,
        } = inject('attendeesState', {});

        const onAttendeesChange = () => {
            emit('update:modelValue', attendees.value);
        };

        return {
            attendees,
            activities,
            attendeeTypes,
            addAttendee,
            addEmptyAttendee,
            removeAttendeeById,
            onAttendeesChange,
        };
    },

    computed: {
        attendeeCount() {
            return this.attendees.length;
        },

        hasMaxAttendees() {
            if (this.max) {
                return this.attendeeCount >= this.max;
            }
            return false;
        },
    },
}
</script>