import EventsList from "./EventsList";
import Form from "./registration/Form";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'events-list': EventsList,
    'registration-form': Form
};
