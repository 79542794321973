<template>
    <div class="attendee">
        <slot name="header"></slot>

        <slot name="contact">
            <fieldset class="grid grid-cols-1 md:grid-cols-2">
                <input v-model="attendee.first_name" type="text" :name="inputName('first_name')" placeholder="First Name">
                <input v-model="attendee.last_name" type="text" :name="inputName('last_name')" placeholder="Last Name">
            </fieldset>

            <fieldset class="grid grid-cols-1">
                <input v-model="attendee.email" type="email" :name="inputName('email')" placeholder="Email">
            </fieldset>
        </slot>

        <transition name="slide-fade">
            <div v-if="hasContactDetails && !isVendor && !attendeeIsGuest">
                <h5>Attendee Type</h5>
                <fieldset class="radio-group">
                    <label v-for="(attendeeType, attendeeTypeKey) in availableAttendeeTypes" :for="inputId('type', attendeeTypeKey)" :key="attendeeTypeKey">
                        <input v-model="attendee.type"
                               :id="inputId('type', attendeeTypeKey)"
                               :name="inputName('attendee_type')"
                               type="radio"
                               :value="attendeeTypeKey"
                               :disabled="attendeeTypeInputDisabled">
                        {{ attendeeTypeKey === 'vip' ? getVipLabel() : attendeeType }}
                    </label>
                </fieldset>
                <transition name="slide-fade">
                    <div class="attendee-type-validation" v-if="validatingVip || showValidationMessage">
                        <p v-if="validatingVip">Validating...</p>
                        <p v-else-if="attendeeIsVip" class="is-success">Success! This is a valid {{ getVipLabel() }}.</p>
                        <p v-else class="is-error">{{ vipNotFoundMessage }}</p>
                    </div>
                </transition>
            </div>
        </transition>

        <!-- ACTIVITIES -->
        <transition name="slide-fade">
            <div v-if="(hasContactDetails || attendeeIsGuest) && !validatingVip">
                <activity-selector v-model="attendee.activities"
                                   :attendee="attendee"
                                   v-model:swag="attendee.swag"></activity-selector>
            </div>
        </transition>

        <!-- GUEST INFO -->
        <transition name="slide-fade">
            <div v-if="canBringGuest" class="attendee attendee--guest">
                <h5 v-if="attendeeIsVip">{{ getVipLabel() }} Guest Information</h5>
                <h5 v-else>Guest Information</h5>

                <fieldset>
                    <p>Are you bringing a guest?</p>
                    <input v-model="bringingGuest" :id="inputId('bringing_guest', 'yes')" :name="inputName('bringing_guest')" type="radio" :value="true">
                    <label :for="inputId('bringing_guest', 'yes')">Yes</label>
                    <input v-model="bringingGuest" :id="inputId('bringing_guest', 'no')" :name="inputName('bringing_guest')" type="radio" :value="false">
                    <label :for="inputId('bringing_guest', 'no')">No</label>
                </fieldset>

                <transition name="slide-fade">
                    <div v-if="bringingGuest">
                        <attendee :attendee="attendee.guest" is-guest>
                            <template #contact>
                                <fieldset class="grid grid-cols-1 md:grid-cols-2">
                                    <input v-model="attendee.guest.first_name" type="text" :name="inputName('guest_first_name')" placeholder="First Name (optional)">
                                    <input v-model="attendee.guest.last_name" type="text" :name="inputName('guest_last_name')" placeholder="Last Name (optional)">
                                </fieldset>

                                <fieldset class="grid grid-cols-1">
                                    <input v-model="attendee.guest.email" type="email" :name="inputName('guest_email')" placeholder="Email (optional)">
                                </fieldset>
                            </template>
                        </attendee>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, computed, inject, watch } from "vue";
import axios from 'axios';
import ActivitySelector from "./ActivitySelector";

export default {
    components: {
        ActivitySelector,
    },

    props: {
        attendee: {
            type: Object
        },
        isGuest: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue'],

    setup(props) {

        const ajaxUrl = inject('ajaxUrl');

        const event = inject('event');

        const unit = inject('unit');

        const isVendor = inject('isVendorForm');

        const getVipLabel = inject('getVipLabel');

        const vipNotFoundMessage = inject('vipNotFoundMessage');

        const eventActivitiesByType = inject('eventActivitiesByType');

        const {
            attendeeTypes,
            addAttendeeGuest,
            removeAttendeeGuest,
        } = inject('attendeesState', {});

        const validatedVip = ref(false);
        const showValidationMessage = ref(false);

        const attendeeIsVip = computed(() => {
            return props.attendee.type === 'vip' && validatedVip.value !== false;
        });

        const availableAttendeeTypes = computed(() => {
            let types = {};
            for (let typeKey of Object.keys(attendeeTypes.value)) {
                // only enable VIP Guest type if attendee is validated VIP
                if (['vip-guest', 'union-member-guest'].includes(typeKey) && !props.isGuest) {
                    continue;
                }
                types[typeKey] = attendeeTypes.value[typeKey];
            }
            return types;
        });

        const activeType = computed(() => props.attendee.type);

        const activeLastName = computed(() => props.attendee.last_name);

        const activeAttendeeActivities = computed(() => {
            return Object.keys(props.attendee.activities);
        });

        const validatingVip = ref(false);

        const attendeeTypeInputDisabled = computed(() => {
            return validatingVip.value === true;
        });

        const sendVipRequest = () => {
            return new Promise((resolve, reject) => {
                validatedVip.value = false;

                let formData = new FormData;
                formData.append('action', 'validate_vip');
                formData.append('unit_post_id', unit.value.id);
                formData.append('last_name', props.attendee.last_name);
                formData.append('first_name', props.attendee.first_name);
                formData.append('email', props.attendee.email);

                axios.post(ajaxUrl.value, formData)
                    .then(response => {
                        if (response.data.success) {
                            validatedVip.value = response.data.vip;
                        } else {
                            props.attendee.type = 'union-member';
                        }
                        showValidationMessage.value = true;
                        resolve();
                    })
                    .catch(err => {
                        showValidationMessage.value = true;
                        reject();
                    });
            });
        };

        const validateVip = async () => {
            showValidationMessage.value = false;
            validatingVip.value = true;
            await sendVipRequest();
            validatingVip.value = false;
            if (showValidationMessage.value && !!validatedVip.value) {
                setTimeout(() => {
                    showValidationMessage.value = false;
                }, 5000);
            }
        }

        const goingToGuestEnabledActivity = computed(() => {
            let typeActivities = eventActivitiesByType.value[activeType.value];
            for (let activeAttendeeActivity of activeAttendeeActivities.value) {
                if (!typeActivities.hasOwnProperty(activeAttendeeActivity)) {
                    continue;
                }
                if (typeActivities[activeAttendeeActivity].allowed_guest === true) {
                    return true;
                }
            }
            return false;
        });

        const canBringGuest = computed(() => {
            if (!attendeeIsVip.value && goingToGuestEnabledActivity.value) {
                return true;
            } else if (!attendeeIsVip.value || attendeeIsGuest.value) {
                return false;
            }
            let guestAllowed = validatedVip.value.meta?.guest_allowed || false;
            return guestAllowed === '1';
        });

        const bringingGuest = ref(false);

        const guestAdded = ref(false);
        
        const attendeeIsGuest = computed(() => {
            return props.isGuest || props.attendee.type === 'vip-guest' || props.attendee.type === 'union-member-guest';
        });

        watch(activeType, (type) => {
            if (type === 'vip') {
                validateVip();
            }
        });

        watch(activeLastName, (name) => {
            showValidationMessage.value = false;
            if (props.attendee.type === 'vip') {
                validateVip();
            }
        });

        watch(canBringGuest, (canBring) => {
            if (canBring && bringingGuest.value && !guestAdded.value) {
                addAttendeeGuest(props.attendee.id);
                guestAdded.value = true;
            }
        });

        watch(bringingGuest, (bringing) => {
            if (bringing && canBringGuest.value && !guestAdded.value) {
                addAttendeeGuest(props.attendee.id);
                guestAdded.value = true;
            } else if (!bringing) {
                guestAdded.value = false;
                removeAttendeeGuest(props.attendee.id);
            }
        });

        return {
            event,
            isVendor,
            getVipLabel,
            availableAttendeeTypes,
            validatingVip,
            attendeeTypeInputDisabled,
            validatedVip,
            attendeeIsVip,
            attendeeIsGuest,
            vipNotFoundMessage,
            showValidationMessage,
            activeType,
            goingToGuestEnabledActivity,
            canBringGuest,
            bringingGuest,
            guestAdded,
            activeAttendeeActivities,
            eventActivitiesByType,
        };
    },

    computed: {
        hasContactDetails() {
            return this.attendee.first_name !== '' && this.attendee.last_name !== '' && this.attendee.email !== '';
        }
    },

    watch: {
        validatedVip: {
            handler(vip) {
                if (!vip) {
                    this.attendee.special_free_activities = [];
                    this.attendee.guest = null;
                    return;
                }
                this.attendee.special_free_activities = vip.meta.complimentary_activities;
                this.attendee.receiving_award = !!vip.meta.receiving_award ? vip.meta.receiving_award : false;
                this.attendee.awards = vip.meta.hasOwnProperty('awards') && Array.isArray(vip.meta.awards) ? vip.meta.awards : [];
            },
            deep: true
        },
        guestAdded(added) {
            if (added && this.canBringGuest && this.attendeeIsVip && Array.isArray(this.validatedVip.meta.guest_complimentary_activities) && this.validatedVip.meta.guest_complimentary_activities.length > 0) {
                this.attendee.guest.special_free_activities = this.validatedVip.meta.guest_complimentary_activities;
            }
        }
    },

    methods: {
        inputName(field) {
            return `attendee_${field}_${this.attendee.id}`;
        },
        inputId(field, suffix = null) {
            let addedSuffix = suffix !== null ? `_${suffix}` : '';
            return `attendee_${field}_${this.attendee.id}${addedSuffix}`;
        },
    }
}
</script>