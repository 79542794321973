<template>
    <div class="activity-selector">
        <div class="activity-selector__main">
            <div class="activity-selector__activities">
                <h5>Activities</h5>

                <fieldset class="checkbox-group">
                    <label v-for="(activity, activityKey) in attendeeTypeActivities" :for="inputId(activityKey)" :key="activityKey" class="input input--checkbox">
                        <input v-model="selected" type="checkbox" :name="'activities_'+attendee.id" :id="inputId(activityKey)" :value="activityKey" />
                        {{ activity.label }} -
                        <span class="price" :class="{'price--strike': isUsingComp(activityKey)}">${{ activity.price }}</span>
                        <span v-if="isUsingComp(activityKey)" class="price__voucher">
                        $0 <a href="javascript:void(0)" @click.prevent="revokeComp(activityKey)"><small>Revoke voucher</small></a>
                        </span>
                        <span v-if="activity.tooltip" class="tooltip__wrapper">
                            <tooltip :content="activity.tooltip" placement="right" arrow hover>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="tooltip__icon">
                                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
                                </svg>
                            </tooltip>
                        </span>
                    </label>
                </fieldset>
            </div>
            <div class="activity-selector__pricing">
                <p><strong>Attendee Cost: ${{ attendee.price }}</strong></p>
                <div class="activity-selector__comps" v-if="hasUnitComps">
                    <p>Remaining Complimentary Activities:</p>
                    <ul>
                        <li v-for="(compCount, activityKey) in currentUnitComps" :key="activityKey">
                            {{ getActivityTypeLabel(activityKey) }}: {{ compCount }}
                            <a v-if="compCount > 0 && !isUsingComp(activityKey) && getActivityPrice(activityKey) > 0"
                               href="javascript:void(0)"
                               @click.prevent="redeemComp(activityKey)">
                                <small>Redeem</small>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="activity-selector__additional" v-if="needAdditionalInfo">
                <h6>Additional Activity Information</h6>

                <div class="activity-selector__additional-items">
                    <div v-for="(swagType, activityKey) in selectedActivitiesSwag" :key="activityKey+'_'+attendee.id" class="activity-selector__additional-item-activity">
                        <fieldset v-for="(swag, swagKey) in swagType" :key="swagKey+'_'+attendee.id" v-if="attendee.swag.hasOwnProperty(activityKey)">
                            <label>{{ getActivityTypeLabel(activityKey) }}: {{ swag.label }}</label>
                            <select :name="'swag-size_'+swagKey+'_'+attendee.id" @change="(event) => onSwagChange(activityKey, swagKey, event)">
                                <option value="">Please select</option>
                                <option v-for="(sizeLabel, sizeKey) in swag.sizes"
                                        :key="sizeKey+'_'+attendee.id"
                                        :value="sizeKey">{{ sizeLabel }}</option>
                            </select>
                        </fieldset>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, computed, inject, onMounted, nextTick } from 'vue';
import Popper from "vue3-popper";

export default {
    components: {
        'tooltip': Popper
    },

    props: {
        modelValue: {
            type: Object
        },
        attendee: {
            type: Object
        },
        swag: {
            type: Object
        }
    },

    emits: ['update:modelValue', 'update:swag'],

    setup(props, { emit }) {

        const selected = ref([]);

        const isVendor = inject('isVendorForm');

        const getActivityTypeLabel = inject('getActivityTypeLabel');

        const eventActivitiesByType = inject('eventActivitiesByType');

        const attendeeType = computed(() => props.attendee.type);

        const attendeeReceivingAward = computed(() => props.attendee.receiving_award);

        const attendeeAwards = computed(() => {
            return attendeeReceivingAward.value ? props.attendee.awards : [];
        });

        const specialFreeActivities = computed(() => {
            return props.attendee.special_free_activities;
        });

        const hasSpecialFreeActivities = computed(() => {
            return specialFreeActivities.value.length > 0;
        });

        const getNonRestrictedActivities = (activitiesObj) => {
            let nonRestrictedActivities = {};
            for (let [activityName, activityData] of Object.entries(activitiesObj)) {
                if (!activityData.has_attendance_restrictions) {
                    nonRestrictedActivities[activityName] = {
                        ...activityData,
                    };
                    continue;
                }
                for (let attendeeAward of attendeeAwards.value) {
                    if (activityData.award_restrictions.includes(attendeeAward)) {
                        nonRestrictedActivities[activityName] = {
                            ...activityData,
                        };
                    }
                }
            }
            return nonRestrictedActivities;
        };

        const attendeeTypeActivities = computed(() => {
            let typeSpecificActivities = eventActivitiesByType.value[attendeeType.value] || {};
            if (!hasSpecialFreeActivities.value) {
                return getNonRestrictedActivities(typeSpecificActivities);
            }
            let attendeeActivities = {};
            for (let [activityName, activityData] of Object.entries(typeSpecificActivities)) {
                attendeeActivities[activityName] = {
                    ...activityData,
                    price: specialFreeActivities.value.includes(activityName) ? 0 : activityData.price
                };
            }
            return getNonRestrictedActivities(attendeeActivities);
        });

        const hasSelectedActivities = computed(() => {
            return selected.value.length > 0;
        });

        const attendeeSwag = ref({});

        const selectedActivitiesSwag = computed(() => {
            let swagActivities = {};
            if (!hasSelectedActivities.value) {
                return swagActivities;
            }
            for (let activity of selected.value) {
                if (attendeeTypeActivities.value[activity]?.hasOwnProperty('has_swag') && attendeeTypeActivities.value[activity]?.has_swag) {
                    swagActivities[activity] = attendeeTypeActivities.value[activity].swag;
                }
            }
            return swagActivities;
        });

        const selectedActivitiesWithSwag = computed(() => {
            return hasSelectedActivities.value ? Object.keys(selectedActivitiesSwag.value) : [];
        });

        const updateAttendeeSwag = (emitUpdate = true) => {
            for (let swagActivity of Object.keys(attendeeSwag.value)) {
                if (!selectedActivitiesWithSwag.value.includes(swagActivity)) {
                    delete attendeeSwag.value[swagActivity];
                }
            }
            for (let selectedActivity of selectedActivitiesWithSwag.value) {
                if (!attendeeSwag.value.hasOwnProperty(selectedActivity)) {
                    attendeeSwag.value[selectedActivity] = {};
                }
                for (let swag of Object.values(selectedActivitiesSwag.value[selectedActivity])) {
                    if (!attendeeSwag.value[selectedActivity].hasOwnProperty(swag.type)) {
                        attendeeSwag.value[selectedActivity][swag.type] = "";
                    }
                }
            }
            if (emitUpdate) {
                emit('update:swag', attendeeSwag.value);
            }
        };

        const needAdditionalInfo = computed(() => {
            return hasSelectedActivities.value && selectedActivitiesWithSwag.value.length > 0;
        });

        const {
            initialUnitComps,
            currentUnitComps,
            useUnitComp,
            removeUsedUnitComp,
        } = inject('comps');

        const hasUnitComps = computed(() => {
            return initialUnitComps.value ? Object.keys(initialUnitComps.value).length > 0 : false;
        });

        const usedComps = ref([]);

        const activitiesWithAvailableComps = computed(() => {
            if (!currentUnitComps.value) {
                return [];
            }

            let comps = [];
            for (let [activity, number] of Object.entries(currentUnitComps.value)) {
                if (number > 0) {
                    comps.push(activity);
                }
            }
            return comps;
        });

        const hasCompAvail = (activityName) => {
            return activitiesWithAvailableComps.value.includes(activityName);
        };

        const isUsingComp = (activityName) => {
            return usedComps.value.includes(activityName);
        };

        const redeemComp = (activityName) => {
            useUnitComp(activityName);
            usedComps.value.push(activityName);
        };

        const revokeComp = (activityName) => {
            let index = usedComps.value.indexOf(activityName);
            if (index >= 0) {
                removeUsedUnitComp(activityName);
                usedComps.value.splice(index, 1);
            }
        };
        
        const onSwagChange = (activityKey, swagKey, e) => {
            let val = e.target.value;

            updateAttendeeSwag(false);

            nextTick(() => {
                attendeeSwag.value[activityKey][swagKey] = val;
                emit('update:swag', attendeeSwag.value);
            });
        };

        const setPresetAttendingActivities = () => {
            for (let [attendeeTypeActivityName, attendeeTypeActivity] of Object.entries(attendeeTypeActivities.value)) {
                if (attendeeTypeActivity.attending) {
                    selected.value.push(attendeeTypeActivityName);
                }
            }
        };

        onMounted(() => {
            updateAttendeeSwag();
            setPresetAttendingActivities();
        });

        return {
            selected,
            hasSelectedActivities,
            selectedActivitiesSwag,
            selectedActivitiesWithSwag,
            updateAttendeeSwag,
            needAdditionalInfo,
            getActivityTypeLabel,
            eventActivitiesByType,
            attendeeType,
            attendeeTypeActivities,
            initialUnitComps,
            currentUnitComps,
            useUnitComp,
            removeUsedUnitComp,
            hasUnitComps,
            usedComps,
            activitiesWithAvailableComps,
            hasCompAvail,
            isUsingComp,
            redeemComp,
            revokeComp,
            onSwagChange,
        };
    },

    watch: {
        selected: {
            handler(activities) {
                this.updateAttendeeActivities();
                this.updateAttendeePrice();
            },
            deep: true
        },

        usedComps: {
            handler(comps) {
                this.attendee.complimentary_activities = comps;
                this.updateAttendeeActivities();
                this.updateAttendeePrice();
            },
            deep: true
        },

        selectedActivitiesWithSwag: {
            handler(selectedActivities) {
                this.updateAttendeeSwag();
            },
            deep: true
        },

        attendeeType(type) {
            this.updateAttendeePrice();
        }
    },

    methods: {
        updateAttendeeActivities() {
            let activities = {};

            for (let activity of this.selected) {
                let usingComp = this.isUsingComp(activity);
                activities[activity] = {
                    price: usingComp ? 0 : this.attendeeTypeActivities[activity].price,
                    comp_used: usingComp
                };
            }

            this.$emit('update:modelValue', activities);
        },

        updateAttendeePrice() {
            this.attendee.price = this.getCurrentTotalPrice();
        },

        getCurrentTotalPrice() {
            let price = 0;
            for (let activity of this.selected) {
                if (!this.attendeeTypeActivities.hasOwnProperty(activity)) {
                    continue;
                }
                // if activity is complimentary, don't add price
                if (this.usedComps.includes(activity)) {
                    continue;
                }
                // else add the price
                price += this.attendeeTypeActivities[activity].price;
            }
            return price;
        },

        getActivityPrice(activity) {
            if (!this.attendeeTypeActivities.hasOwnProperty(activity)) {
                return 0;
            }
            return this.attendeeTypeActivities[activity].price;
        },

        inputName(field) {
            return `activity_${field}_${this.attendee.id}`;
        },
        inputId(field, suffix = null) {
            let addedSuffix = suffix !== null ? `_${suffix}` : '';
            return `activity_${field}_${this.attendee.id}${addedSuffix}`;
        },
    }
}
</script>